$screen-small: 768px;
$screen-regular: 769px;
$screen-large: 1200px;

$bg-light: #f5faff;
$bg-semi-light: #dededf;
$bg-progress: #b5b5b5;
$bg-dark: #141414;
$bg-header: #253239;
$bg-header-light: #ffffff;
$bg-page: #f0f2f5;

$neutral5: #d9d9d9;

$title5: rgba(0, 0, 0, 0.85);
$character-secondary-45: rgba(0, 0, 0, 0.45);

$color-light: #ffffff;
$color-semi-light: #d9dcdd;
$color-dark: #141414;
$color-default: #666666;
$color-disabled: #dededf;
$color-arrows: #8388a8;

$white: #ffffff;
$blue: #1651b6;
$blue-dark: #113d89;
$blue-darker: #081c40;
$blue-light: #c5d4ed;
$red: #ef7575;

$stroke-color: #d9d9d9;

$padding-default: 24px;
$padding-small: 12px;
$padding-header: 10px;

$layout-padding: 50px;
$layout-padding-small-screen: 40px;
$margin-small: 10px;
$margin-default: 20px;

$border-radius: 6px !default;
$box-shadow: 0px 1px 4px rgba(21, 34, 50, 0.08);

$font-family: "Poppins", sans-serif;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-size-super-large: 36px;
$font-size-large: 26px;
$font-size-semi-large: 20px;
$font-size-regular: 14px;
$font-size-small: 12px;

$logo-height: 100px;
