@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

html,
body,
#root {
  min-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.11px;

  #root {
    /* Development Mode */
    a.dev {
      display: none;
      border: 1px double red;
      color: red !important;
    }

    span.dev {
      display: none;
      color: red !important;
    }

    button {
      &.dev {
        display: none;
        border: 1px double red;
        color: red !important;
        background: #253239;
      }

      &.ant-btn-background-ghost {
        &.dev {
          display: none;
          background: none;
          color: red !important;
          border: 1px double red !important;
          padding: 2px 5px;
          border-radius: 0px;
          font-weight: 300 !important;
          margin-left: 5px;
        }
      }
    }

    .ant-tabs {
      &.dev {
        display: none;
        border: 1px solid red !important;
        padding: 10px;
      }
    }

    .ant-table-wrapper {
      &.dev {
        display: none;
        border: 1px solid red !important;
        box-shadow: 0px 0px 2px red;
        -webkit-box-shadow: 0px 0px 2px red;
        -moz-box-shadow: 0px 0px 2px red;
      }
    }

    table {
      &.dev {
        display: none;
        border: 1px solid red !important;
        box-shadow: 0px 0px 2px red;
        -webkit-box-shadow: 0px 0px 2px red;
        -moz-box-shadow: 0px 0px 2px red;
      }
    }

    .ant-checkbox-wrapper {
      &.dev {
        display: none;
        border: 1px solid red !important;
        padding: 0px 5px;
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: #1890ff;
    }

    .ant-input {
      &.dev {
        display: none;
        border: 1px solid red !important;
        box-shadow: 0px 0px 2px red;
        -webkit-box-shadow: 0px 0px 2px red;
        -moz-box-shadow: 0px 0px 2px red;
      }
    }

    li {
      &.dev {
        display: none;
        border: 1px solid red !important;
        box-shadow: 0px 0px 2px red;
        -webkit-box-shadow: 0px 0px 2px red;
        -moz-box-shadow: 0px 0px 2px red;
        padding: 1px 5px;
        margin-bottom: 2px;
      }
    }

    .leaflet-container {
      background-color: #fff;

      .leaflet-control-attribution {
        background-color: rgba(255, 255, 255, 0.2);
      }

      .leaflet-pane {
        .leaflet-tile-pane {
          opacity: 1;
        }
      }
    }

    /* End Development Mode */
    .echarts-for-react {
      cursor: ns-resize;

      h4 {
        display: none;
      }

      div {
        &:last-child {
          div {
            &:last-child {
              div {
                &:not(.ant-table-container) {
                  margin-right: 0px !important;
                  border-radius: 4px !important;
                  text-transform: uppercase;
                  color: #253239 !important;
                  background-color: #fff !important;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
  }

  .ant-select-dropdown {
    .ant-select-item {
      &.ant-select-item-group {
        text-transform: capitalize;
      }

      &.ant-select-item-option {
        text-transform: capitalize;
      }
    }

    .ant-select-item-option-content {
      text-transform: capitalize;
    }
  }
}
