@import "../../variables";

#root {
  #manageData {
    tr.ant-table-expanded-row:hover > td,
    tr.ant-table-expanded-row > td {
      padding: 48px 16px 16px;
    }
    .data-detail {
      height: 450px;
      overflow-y: scroll;
      width: 80%;
      margin: 8px 10%;
      padding: 0 12px;
      .pending-data-wrapper {
        h3 {
          padding: 12px 16px;
          background: #eaeaed;
          font-size: 14px;
          font-weight: $font-weight-bold;
          margin-bottom: 0;
        }
        border: solid #e9e9e9 1px;
        margin-bottom: 20px;
        .ant-table {
          thead {
            tr {
              th {
                background-color: #f2f2f3;
                font-weight: 500;
              }
            }
          }
          .ant-table-cell .ant-btn {
            margin-left: 10px;
          }
          tr.row-edited td {
            background-color: #fefebe;
          }
          tr.ant-table-row.row-edited:hover > td,
          tr.row-edited > td.ant-table-cell-row-hover {
            background: #fefebe;
          }
          .expand-icon {
            color: #959495;
          }
        }
      }
      & + div {
        padding: 8px 10% 0;
        text-align: right;
      }
    }
    .history-table-wrapper {
      padding: 12px;
      .ant-table {
        margin: 0;
        border: solid #e9e9e9 1px;
        tr {
          td {
            color: #959495;
          }
        }
      }
    }
    .button-save {
      position: absolute;
      top: 0px;
      right: 22px;
    }
    .ant-table {
      tr.row-edited td {
        background-color: #fefebe;
      }
      tr.ant-table-row.row-edited:hover > td,
      tr.row-edited > td.ant-table-cell-row-hover {
        background: #fefebe;
      }
      tr.row-normal {
        cursor: pointer;
      }
    }
  }
  .ant-btn.ant-btn-link:hover {
    border-color: transparent;
  }
}
