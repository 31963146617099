@import "../../variables";

#root {
  #news-events {
    h1 {
      text-align: left;
      font: normal normal 600 #{$font-size-super-large}/ 82px $font-family;
      letter-spacing: 0.49px;
      color: #131523;
      opacity: 1;
    }
    h2 {
      font-size: 27px;
      font-weight: $font-weight-semi-bold;
      color: #595b65;
      margin-bottom: 14px;
    }
    .updates {
      .update {
        margin-bottom: 12px;
        padding: 12px;
        text-align: left;
        h3 {
          font-size: 18px;
          font-weight: $font-weight-semi-bold;
        }
        .placeholder {
          background-color: #ececf5;
          width: 100%;
          height: 200px;
        }
      }
    }
    .events {
      margin-top: 12px;
      .event {
        background-color: #fff;
        padding: 12px;
        border-radius: 6px;
        box-shadow: 0px 1px 4px rgba(21, 34, 50, 0.08);
        h3 {
          line-height: 22px;
          font-size: 18px;
          margin-bottom: 0px;
          font-weight: $font-weight-semi-bold;
        }
        div:first-of-type {
          color: #74747e;
          font-size: 14px;
        }
        .placeholder {
          margin: 12px 0 8px;
          background-color: #ececf5;
          width: 100%;
          height: 150px;
        }
      }
    }
  }
}
