@import "../../variables";

#root {
  #add-organisation {
    min-height: 65vh;
    padding-bottom: 30px;

    .ant-card {
      text-align: left;
      margin-bottom: 30px;

      .form-row-adm {
        h3 {
          margin-top: 12px;
          margin-bottom: 12px;
          font-size: 15px;
          font-weight: 700;
        }
        .ant-space {
          width: 100%;

          .ant-space-item {
            width: 100%;

            .ant-select {
              width: 100%;
            }
          }
        }
      }

      .form-row-adm,
      .form-row {
        margin-bottom: 8px;

        label {
          font-weight: $font-weight-semi-bold !important;
        }

        input,
        .ant-select {
          margin-top: 4px;

          span.opt-desc {
            display: block;
            font-size: 12px;
            font-weight: 300;
            font-style: italic;
            color: gray;
          }
        }
      }

      .ant-row {
        .ant-col {
          padding-right: 10px;

          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
    .ant-row-end {
      .ant-form-item {
        margin-bottom: 0;
        .ant-checkbox-wrapper {
          margin-right: 12px;
        }
      }
    }
  }
}
