@import "../../variables";

#root {
  .widget-wrap {
    .ant-spin-container {
      display: flex;
      flex-direction: column;
      height: 535px;
      .ant-table {
        flex: 1;
        .ant-table-row-level-0 {
          th.ant-table-cell {
            background-color: #e7eaee;
            color: #9097a1;
          }
          th.ant-table-cell:first-child {
            padding-left: 14px;
          }
          td.ant-table-cell {
            background-color: #fff;
            color: #5d5d5d;
            font-size: 13px;
            border-bottom: 1px solid #0000000f;
          }
          td.ant-table-cell:first-child {
            padding-left: 14px;
            font-weight: 500;
            color: #333;
          }
          td.ant-table-cell:nth-child(2) {
            background-color: #e4f7eb;
          }
          td.ant-table-cell:nth-child(3) {
            background-color: #f5fae6;
          }
          td.ant-table-cell:nth-child(4) {
            background-color: #f5e7e7;
            padding-right: 12px;
          }
        }
      }
    }
  }
  .chart-wrap {
    padding: 0;
    text-align: left;
    margin-bottom: 16px;
    height: auto;
    .ant-card-body {
      padding: 15px 16px;
      h3 {
        font-weight: 600;
        margin: 0px 4px 0px;
        .anticon {
          margin: 0 10px;
          color: #9191a2;
          font-size: 16px;
        }
      }
      .ant-checkbox-wrapper {
        color: #0005;
        font-size: 13px;
      }
      .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
        color: #0008;
      }
      .chart-inner {
        padding: 0;
        margin-top: 8px;
        min-height: 180px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
