@import "../../variables";

#root {
  .ant-card {
    height: auto;
  }
  #profile {
    padding: 24px;
    .ant-card {
      margin-top: 20px;
    }
    h1 {
      text-align: left;
      font: normal normal 600 $font-size-large $font-family;
      letter-spacing: 0.49px;

      //color: #595a65;
      opacity: 1;
    }

    .ant-card {
      text-align: left;
      padding: 0;
      padding-bottom: 72px;

      .ant-card-body {
        padding: 24px 40px;

        ul.profile-detail {
          list-style-type: none;
          padding-left: 0;

          li {
            border-bottom: solid #dfe1ef 1px;
            padding: 12px 0;

            &:last-child {
              border-bottom: none;
            }

            h3 {
              color: #71737b;
              font-size: 14px;
              font-weight: $font-weight-medium;
              margin-bottom: 8px;
            }

            p {
              margin-bottom: 0;
              font-weight: $font-weight-medium;
            }

            .anticon-right {
              font-size: 13px;
            }
          }
        }

        .ant-tabs {
          margin-top: 20px;

          .ant-table-cell {
            .ant-progress {
              margin-right: 20px;
            }

            .expand-body {
              padding: 20px 0;
              text-align: center;
              background-color: #f2f2f5;
            }
          }

          .ant-table-cell:nth-child(2) {
            .ant-row {
              div:first-child {
                margin-right: 10px;
              }

              .ant-col:nth-child(2) {
                div:nth-child(2) {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.ant-modal-content {
  // To be moved to separate component scss module
  p {
    text-align: center;
    margin-bottom: 20px;
  }

  .batch-name-field {
    margin-bottom: 20px;
  }

  .ant-card {
    border: solid #ebf4fb 1px;

    .ant-table {
      width: 100%;
      text-align: left;
      margin-bottom: 16px;

      th,
      td {
        padding: 11px;
        border-bottom: solid #ebf4fb 1px;
      }

      th {
        color: #b2b1b2;
        font-weight: $font-weight-normal;
      }

      tr td:last-child {
        text-align: right;
        padding-right: 32px;
      }
    }
  }

  .ant-modal-footer {
    padding-bottom: 30px;
  }

  .ant-input {
    &:focus,
    &:hover {
      border-right-width: 16px !important;
    }
  }

  textarea {
    margin: 8px 0 15px;
    border: solid $color-semi-light 16px;
  }
}
