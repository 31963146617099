@import "../../variables";

#root {
  #form {
    .ant-affix {
      .webform-progress-bar {
        transition: background-color linear 0.2s;
      }
    }

    button {
      border-radius: 32px;
    }

    #CLTS {
      &.ant-form {
        .ant-card {
          box-shadow: none;
        }
      }
    }

    .webform {
      h1 {
        font-family: $font-family;
        font-size: $font-size-large;
        letter-spacing: 0.49px;
        color: $color-dark;
      }

      .arf-container {
        h1 {
          display: none;
        }
      }

      .form-container {
        margin-top: 50px;
        margin-bottom: 50px;
        border-left: 1px solid #eee;
        border-bottom: 1px solid #eee;
      }

      .arf-form-header {
        padding: 0px 0px 40px;
        background: $bg-light;
      }

      .arf-sidebar {
        border-top: none;
        border: none;
        border-right: 1px solid #eee;
        color: $color-dark;

        .arf-sidebar-header {
          font-family: $font-family;
        }

        .arf-sidebar-list {
          font-family: $font-family;
          color: $color-dark;
        }

        .ant-list-items {
          .arf-complete {
            color: $blue;
          }
        }
      }

      .progress-bar {
        .ant-progress-outer {
          background: $white;
          padding: 10px 20px;
          border-top: 1px solid #eee;

          .ant-progress-inner {
            width: calc(100% - 40px);
          }
        }

        .ant-progress-text {
          margin-left: 0px;
          text-align: left;
          width: auto;
        }
      }

      .arf-field-group-header {
        font-family: $font-family;
        font-size: $font-size-large;
        color: $color-dark;
      }

      .arf-field-group {
        font-family: $font-family;
        padding: 0px;
        box-shadow: none;
        border: none;

        .arf-field {
          font-family: $font-family;

          .ant-form-item-control-input-content {
            text-align: left;
          }
        }
      }
    }

    .ant-result {
      background: $white;

      .ant-result-title {
        font-family: $font-family;
        font-weight: 600;
        font-size: $font-size-large;
        line-height: 1.35;
        color: $color-dark;
      }

      .ant-result-subtitle {
        color: #3b3b3b;
        font-size: 14px;
      }
    }

    .ant-card {
      text-align: left;
      padding: 0;
      padding-bottom: 72px;
    }
  }
  .table-wrapper h1 {
    line-height: 1.8;
  }
}
