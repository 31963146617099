@import "../../variables";

#root {
  #submissions {
    .ant-card-body {
      .main-tab {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            padding-bottom: 25px;
            padding-top: 25px;
          }
        }
      }

      .ant-table-thead tr th {
        font-weight: $font-weight-bold;
      }

      ul.option-list {
        padding-inline-start: 17.5px;
        margin-block-end: 0px;
      }

      .main-table {
        .ant-table-content {
          min-height: 305px;
        }
      }

      .ant-table-tbody {
        .ant-table-row {
          .ant-table-cell:first-child {
            color: $color-default;
            vertical-align: top;
          }
          .sticky {
            position: sticky;
            top: 0;
            left: 0;
            z-index: 1;
          }
          .ant-table-cell:nth-child(2) {
            .ant-row {
              justify-content: flex-start;

              div:first-child {
                margin-right: 10px;
                color: $color-default;
              }

              div:nth-child(2) {
                div:nth-child(2) {
                  font-size: $font-size-small;
                  font-weight: $font-weight-normal;
                }
              }
            }
          }

          .ant-table-cell:nth-child(3) {
            .ant-row {
              .ant-col:first-child {
                padding-right: 5px;

                .ant-progress-bg,
                .ant-progress-success-bg {
                  background-color: $bg-progress;
                }
              }
            }
          }
        }

        .ant-table-row.table-row-expanded {
          color: $blue;

          .ant-table-cell:first-child {
            color: $color-default;
          }

          .ant-table-cell:nth-child(2) {
            .ant-row {
              div:first-child {
                margin-right: 10px;
                color: $blue;
              }
            }
          }

          .ant-table-cell:nth-child(3) {
            .ant-row {
              .ant-col:first-child {
                .ant-progress-bg,
                .ant-progress-success-bg {
                  background-color: $blue;
                }
              }
            }
          }
        }

        tr.ant-table-expanded-row > td > div {
          width: 80%;
          margin: 0 10%;
          background-color: #faf9fb;
          padding: 0 0 32px;

          h3 {
            padding: 12px 16px;
            background: #eaeaed;
            font-size: 14px;
            font-weight: $font-weight-bold;
            margin-bottom: 0;
          }

          .comments {
            border: 1px solid #d9dcdd;
            padding: 12px 16px;
          }

          .ant-tabs {
            margin: 20px 0 10px;

            .ant-tabs-nav {
              margin-bottom: 8px;
              font-weight: $font-weight-semi-bold;
            }

            .ant-tabs-content-holder {
              font-size: $font-size-small;

              table,
              .ant-table {
                thead {
                  tr {
                    th {
                      background-color: $bg-semi-light;
                      padding: 18px 24px 15px;
                      font-weight: $font-weight-semi-bold;
                      white-space: nowrap;
                    }
                  }
                }

                tbody {
                  tr {
                    td {
                      padding: 18px 24px 15px;
                      border-bottom: solid $color-semi-light 1px;
                    }
                  }
                }
              }

              .ant-tabs-tabpane:first-child {
                table,
                .ant-table {
                  tbody {
                    tr {
                      td:nth-child(2) {
                        color: $blue;
                      }
                    }
                  }
                }
              }
            }
          }

          .ant-input {
            &:focus,
            &:hover {
              border-right-width: 16px !important;
            }
          }

          textarea {
            margin: 0px 0px 15px;
            border: solid $color-semi-light 16px;
          }
        }

        tr.ant-table-expanded-row > td > .pending-data-actions {
          margin-top: 12px;
        }
      }
    }

    .monitoring-icon {
      margin-left: 10px;
      span {
        width: 20px;
        height: 20px;
      }
      svg {
        width: 100%;
        height: 100%;
        fill: black;
      }
    }

    .ant-table {
      tr.row-edited td {
        background-color: #fefebe;
      }

      tr.ant-table-row.row-edited:hover > td,
      tr.row-edited > td.ant-table-cell-row-hover {
        background: #fefebe;
      }
    }

    .ant-table-expanded-row {
      .ant-table-cell {
        .pending-data-actions {
          position: absolute;
          top: -6px;
          right: -9px;
        }
      }
    }

    .ant-table-expanded-row {
      .ant-table-cell {
        .pending-data-action-reject {
          position: absolute;
          top: 6px;
          right: 16px;
        }
      }
    }

    tr.ant-table-expanded-row > td {
      padding-top: 3rem;
    }

    .pending-data-outer {
      height: 500px;
      overflow-y: scroll;

      .pending-data-wrapper {
        border: solid #e9e9e9 1px;
        margin-bottom: 20px;
        padding: 1rem;
        .ant-table {
          thead {
            tr {
              th {
                background-color: #f2f2f3;
                font-weight: 500;
              }
            }
          }
        }
      }

      .pending-data-wrapper:last-child {
        margin-bottom: 0;
      }
    }
  }
  #upload-detail {
    .blue {
      color: $blue;
    }
  }
}
