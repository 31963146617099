@import "../../variables";

#root {
  min-height: 100vh;
  .background {
    content: "";
    width: 100%;
    position: absolute;
    height: 100vh;
    top: 0px;
  }

  .contact-text {
    color: #000;
    font-size: 13px;
    text-align: center;
    padding-top: 16px;
    margin: 0px;
    a {
      color: $blue;
    }
  }

  #login {
    background-color: $bg-light;
    min-height: 100vh;
    margin: -24px -50px;
    padding: 40px;
    .loading-container {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      h2,
      small {
        color: $bg-dark !important;
      }
    }

    .logo {
      position: absolute;
      left: $layout-padding;
      top: $padding-header;
      font-weight: $font-weight-semi-bold;
      align-items: center;

      img,
      h1 {
        display: inline-block;
      }

      h1 {
        margin: auto;
        display: inline-block;
        color: $white;
        font-weight: $font-weight-semi-bold;
        font-size: $font-size-large;
        margin-bottom: 0px;
        padding-left: $margin-default;
        vertical-align: middle;
        letter-spacing: 12px;

        small {
          display: block;
          font-size: 14px;
          letter-spacing: normal;
        }
      }

      img {
        height: $logo-height;
      }
    }

    .wrapper {
      .right-side,
      .left-side {
        align-items: middle;
      }

      .right-side {
        h1 {
          color: $blue;
          font-weight: $font-weight-semi-bold;
          margin-bottom: 0px;
          text-align: center;

          small {
            font-size: $font-size-regular;
            font-weight: $font-weight-normal;
          }
        }
        img {
          margin: 0 auto;
          display: block;
        }

        h2 {
          color: $white;
          margin-top: 12px;

          small {
            color: #fffa;
          }
        }
        .login-form-container {
          background-color: white;
          max-width: 450px;
          margin: 0 auto;
          box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.1);
          padding: 24px;
          .login-content {
            padding: 40px 0px;
          }
          button {
            margin: 0 auto;
            display: block;
          }
        }

        .ant-form {
          &.ant-form-vertical {
            .ant-form-item {
              .ant-form-item-label {
                label {
                  color: $blue-darker;
                  font-size: 14px;
                  font-weight: $font-weight-semi-bold;
                }
              }

              .ant-input-affix-wrapper {
                padding: 0px 10px 0px 0px;
              }
              input {
                padding: 10px 24px;
                border-color: $stroke-color;
                color: $color-dark;
                font-weight: $font-weight-semi-bold;
              }

              .ant-checkbox-wrapper {
                color: $white;
              }

              .login-form-forgot {
                float: right;
                color: $blue;
              }
            }
          }

          .disclaimer {
            color: $blue-dark;
            font-size: 12px;
            font-style: normal;
          }
        }

        .ant-checkbox-group {
          color: $white;
          margin-bottom: 10px;

          .ant-checkbox-wrapper {
            color: $white;
            padding: 4px;
            background-color: rgba(0, 88, 255, 0.4);
            border-radius: $border-radius;
            font-size: 12px;
            margin-bottom: 9px;

            .ant-checkbox-inner {
              background-color: transparent;
              border: none;
              width: 0px;
              height: 0px;
            }
            .ant-checkbox-checked {
              padding-left: 5px;
              .ant-checkbox-inner {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }
    }
  }
}
