@import "../../variables";

#root {
  #settings {
    min-height: 65vh;

    .ant-card {
      margin-top: 20px;
    }

    .submission {
      font-size: 27px;
      font-weight: 600;
      color: #595b65;
    }

    .ant-card {
      text-align: left;
      padding: 0;
      padding-bottom: 72px;

      .ant-card-body {
        padding: 24px 40px;

        .ant-tabs {
          margin-top: 20px;

          .ant-table-wrapper {
            border: 1px solid #f7f7f7;
          }

          .ant-table-cell {
            .ant-progress {
              margin-right: 20px;
            }

            .expand-body {
              padding: 20px 0;
              text-align: center;
              background-color: #f2f2f5;
            }
          }

          .ant-table-cell:nth-child(2) {
            .row {
              justify-content: flex-start;

              div:first-child {
                margin-right: 10px;
              }

              div:nth-child(3) {
                div:nth-child(3) {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }

    a {
      &.explore {
        display: block;
        position: absolute;
        bottom: 40px;
        width: 100%;
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .flex-1 {
        flex: 1;
        padding-right: 17%;
        text-align: left;

        h2 {
          font-size: 27px;
          font-weight: $font-weight-semi-bold;
          color: #595b65;
          margin-top: 20px;
        }
      }
    }

    .ant-row {
      &.approval-links {
        margin-top: 20px;
      }
    }
  }
}
