@import "../../variables";

#root {
  .ant-breadcrumb {
    h2 {
      font-size: 28px;
    }

    span:last-child span,
    span:last-child span {
      color: #262626;
    }
  }
}
