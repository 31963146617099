@import "../../variables";

#root {
  #control-center {
    margin: -24px -50px;
    position: relative;
    .ant-card {
      margin-bottom: 20px;
    }
    .ant-layout {
      min-height: calc(100vh - 86px);
      &.site-layout {
        margin-left: 200px;
        .description-container {
          padding: 16px 24px;
          background: white;
        }
      }
      .site-layout-background {
        width: 200px;
      }
      .ant-layout-sider {
        overflow: auto;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
        margin-top: 86px;
        .ant-menu-item {
          padding: 0px 16px !important;
          margin-bottom: 0px;
        }
        .ant-menu-item-only-child,
        .ant-menu-submenu-title {
          padding-left: 16px !important;
        }
      }
    }
    .profile-container {
      margin-top: 10px;
      .profle-wrapper {
        display: flex;
        align-items: center;
        gap: 24px;
        padding-top: 10px;
        h2 {
          margin-bottom: 0px;
        }
        p {
          font-size: $font-size-regular;
          color: $character-secondary-45;
          margin-bottom: 0px;
        }
      }
    }

    #panel-submission {
      &.ant-card {
        padding-bottom: 0px;
        margin: 0;
      }

      .submission {
        font-size: 27px;
        font-weight: 600;
        color: #595b65;
      }
    }

    .ant-card {
      text-align: left;
      padding: 0;
      padding-bottom: 72px;

      .ant-card-body {
        padding: 24px 40px;

        .ant-tabs {
          margin-top: 20px;

          .ant-table-wrapper {
            border: 1px solid #f7f7f7;
          }

          .ant-table-cell {
            .ant-progress {
              margin-right: 20px;
            }

            .expand-body {
              padding: 20px 0;
              text-align: center;
              background-color: #f2f2f5;
            }
          }

          .ant-table-cell:nth-child(2) {
            .row {
              justify-content: flex-start;

              div:first-child {
                margin-right: 10px;
              }

              div:nth-child(3) {
                div:nth-child(3) {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }

    a {
      &.explore {
        display: block;
        position: absolute;
        bottom: 40px;
        width: 100%;
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .flex-1 {
        flex: 1;
        padding-right: 17%;
        text-align: left;

        h2 {
          font-size: 27px;
          font-weight: $font-weight-semi-bold;
          color: #595b65;
          margin-top: 20px;
        }
      }
    }

    .ant-row {
      &.approval-links {
        margin-top: 20px;
      }
    }
  }
}
