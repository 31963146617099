#root {
  #masterData {
    #add-administration {
      .ant-card {
        margin-bottom: 16px;
        .ant-space,
        .ant-space .ant-space-item {
          width: 100%;
        }
      }
    }
    .ant-table {
      .ant-table-tbody {
        .ant-table-cell {
          vertical-align: baseline;
        }
      }
    }
    .attribute-value {
      display: inline-table;
      list-style: "- ";
    }
  }
}
