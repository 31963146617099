@import "../../variables";

#root {
  #reports {
    min-height: 600px;

    h2 {
      margin: 28px 0 16px;
    }

    .ant-card {
      text-align: left;
      padding: 6px;

      .ant-btn {
        margin-top: 12px;
      }
    }
  }

  #report {
    min-height: 600px;

    .report-header {
      h3 {
        font-size: 13px;
        margin-bottom: 32px;
      }
    }

    .ant-checkbox-wrapper {
      display: none;
    }

    .report-footer {
      margin-top: 32px;
    }

    .toolbar {
      margin-bottom: 24px;

      .mx {
        margin-right: 12px;
      }
    }
  }
}
