@import "../../variables";

.ant-modal-content {
  .ant-carousel {
    h3 {
      margin-top: 24px;
    }
    p {
      text-align: left;
      margin: 0 0;
    }
  }
}
