@import "../../variables";

#root {
  .ant-input-search {
    .ant-input-group-addon {
      left: 0;
    }
  }
  .advanced-filters {
    margin-top: 12px;
    .ant-card {
      text-align: left;
    }
    .filter-options {
      margin-top: 12px;
      .ant-col {
        margin-bottom: 8px;
      }
    }
    .filters-active {
      padding-top: 12px;
      .ant-tag {
        margin-bottom: 6px;
      }
    }
  }
  .form-dropdown {
    width: 100%;
  }
  .form-dropdown.form-dropdown-title {
    border-bottom: solid #d9d9e4 1px;
    padding-bottom: 4px;
    background-color: transparent;
    .ant-select-selector {
      padding-left: 0;
      border: none;
      background-color: transparent;
      .ant-select-selection-item {
        font-size: 22px;
        font-weight: 600;
        line-height: 38px;
      }
    }
    .ant-select-selector::after {
      line-height: 38px;
    }
    .ant-select-arrow {
      color: #242426;
    }
  }
  .ant-divider {
    margin-top: 12px;
  }
  .custom-select {
    min-width: 180px;
    .ant-select-selection-overflow-item {
      .ant-select-selection-item {
        padding-right: 18px !important;
      }
    }
  }
}
.ant-popover-inner-content {
  display: none;
}
