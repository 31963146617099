#masterDataEntities {
  #add-entity {
    .ant-space-vertical {
      width: 100%;
      justify-content: flex-start;
      text-align: left;
      margin-bottom: 16px;
    }
    .ant-card {
      margin-bottom: 16px;
    }
  }
}
